import classes from "./subjects.module.scss";
import Card from "./Card";

const Subject = (props) => {
  return (
    <Card className={classes.container}>
      <div className={classes.subjectName}>{props.name}</div>
      {props.children}
    </Card>
  );
};

export default Subject;
