import "./App.css";
import React from "react";
import Login from "./components/Pages/Login";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import Course from "./components/Pages/Course";
import Header from "./components/UI/Header";
import Teachers from "./components/Pages/Teachers";
import Stories from "./components/Pages/Stories";
import Quiz from "./components/Pages/Quiz";
import LiveClass from "./components/Pages/LiveClass";
import Competition from "./components/Pages/Competition";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Route path="/" exact>
        <Login />
      </Route>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/teachers">
        <Teachers />
      </Route>
      <Route path="/stories">
        <Stories />
      </Route>
      <Route path="/quiz">
        <Quiz />
      </Route>
      <Route path="/courses">
        <Course />
      </Route>
      <Route path="/crash/courses">
        <Course />
      </Route>
      <Route path="/live/class">
        <LiveClass />
      </Route>
      <Route path="/competition">
        <Competition />
      </Route>
    </BrowserRouter>
  );
}

export default App;
