import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "../../assets/lyrics-logo.png";
import { sessionToken } from "../../store/auth";
import classes from "./header.module.scss";
const Header = () => {
  const token = useSelector(sessionToken);
  return (
    <div className={classes.container}>
      {token !== "" && (
        <div className={classes.subcontainer}>
          <NavLink
            to="/home"
            className={classes.home}
            activeStyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Home
          </NavLink>
          <NavLink
            to="/teachers"
            className={classes.home}
            activeStyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Teachers
          </NavLink>
          <NavLink
            to="/stories"
            className={classes.home}
            activeStyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Stories
          </NavLink>
          <NavLink
            to="/quiz"
            className={classes.home}
            activeStyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Quiz
          </NavLink>
          <NavLink
            to="/live/class"
            className={classes.home}
            activeStyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Live Class
          </NavLink>
          {/* <NavLink
            to="/competition"
            className={classes.home}
            activeStyle={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            Competition
          </NavLink> */}
        </div>
        // <h3 className={classes.home} onClick={onHomeHandler}>
        //   Home
        // </h3>
      )}

      <div className={classes.logo_container}>
        <img
          src={logo}
          alt="Lyrics Academy of Music"
          className={classes.logo}
        />
      </div>
    </div>
  );
};

export default Header;
