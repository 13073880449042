import Modal from "../Modal";
import Card from "../Card";
import classes from "./storyform.module.scss";
import { TextField, Button } from "@material-ui/core";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionToken } from "../../../store/auth";
import { createStoryApi } from "../../../store/forms";

const StoryForm = (props) => {
  const token = useSelector(sessionToken);
  const [image, setimage] = useState();
  const [file, setFile] = useState();
  const [imageThumb, setimageThumb] = useState();
  const [fileThumb, setFileThumb] = useState();
  const storyName = useRef();
  const dispatch = useDispatch();
  const addSubjectHandler = () => {
    dispatch(createStoryApi(token, storyName.current.value, file, fileThumb));
    props.setIsStory(false);
  };
  const uploadStory = (event) => {
    if (event.target.files[0].size > 100000) {
      alert("File size is too large, Compress Image under 100KB");
      return;
    } else {
      setFile(event.target.files[0]);
      setimage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const uploadStoryThumbnail = (event) => {
    if (event.target.files[0].size > 50000) {
      alert("Thumbnail size is too large, Compress Image under 10KB");
      return;
    } else {
      setFileThumb(event.target.files[0]);
      setimageThumb(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <Modal>
      <Card className={classes.container}>
        <div
          onClick={() => props.setIsStory(false)}
          className={classes.close_button}
        >
          X
        </div>
        <div className={classes.sub_container}>
          <TextField label="Enter Story Title" inputRef={storyName}></TextField>
          <div className={classes.image_container}>
            <img src={image} alt="StoryImage" className={classes.image} />
            <input
              type="file"
              onChange={uploadStory}
              className={classes.image_input}
              id="file"
            />
            <label htmlFor="file">Select Story Image</label>
            <img
              src={imageThumb}
              alt="StoryThumbnail"
              className={classes.image_thumbnail}
            />
            <input
              type="file"
              onChange={uploadStoryThumbnail}
              className={classes.image_input}
              id="fileThumb"
            />
            <label htmlFor="fileThumb">Select Thumbnail</label>
          </div>
          <Button color="primary" onClick={addSubjectHandler}>
            Add Story
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default StoryForm;
