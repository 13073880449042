import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./home.module.scss";
import {
  getCoursesApi,
  getCrashCoursesApi,
  getSubjects,
} from "../../store/uiStore";
import Subject from "../UI/Subjects";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TeacherForm from "../UI/Form/TeacherForm";
import SubjectForm from "../UI/Form/SubjectForm";
import { useHistory } from "react-router-dom";
import { sessionToken } from "../../store/auth";
import { uiActions } from "../../store";

const useStyles = makeStyles({
  button: {
    margin: "1rem",
  },
});

const Home = () => {
  const history = useHistory();
  const [isSubject, setIsSubject] = useState(false);
  const [isteacher, setIsTeacher] = useState(false);
  const token = useSelector(sessionToken);
  const dispatch = useDispatch();
  const subjectAddHandler = () => {
    setIsSubject(true);
  };
  const teacherAddHandler = () => {
    setIsTeacher(true);
  };
  const openCourseHandler = (id, name, type) => {
    dispatch(
      uiActions.setSubjectDetails({
        subjectName: name,
        subjectId: id,
      })
    );
    if (type === "Course") {
      dispatch(getCoursesApi(token, id));
      history.push("/courses");
    } else if (type === "CrashCourse") {
      dispatch(getCrashCoursesApi(token, id));
      history.push("/crash/courses");
    }
  };

  const classes_api = useStyles();
  const subjects = useSelector(getSubjects);
  return (
    <div className={classes.container}>
      {subjects === "" ? (
        <Subject name="Loading..."></Subject>
      ) : (
        <div>
          <Button
            variant="contained"
            color="secondary"
            className={classes_api.button}
            onClick={subjectAddHandler}
          >
            Add New Subject
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes_api.button}
            onClick={teacherAddHandler}
          >
            Add New Teacher
          </Button>

          <div className={classes.subjects}>
            {subjects.map((sub, idx) => (
              <Subject key={sub._id} name={sub.name} subject_id={sub._id}>
                <div className={classes.explore_buttons}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes_api.button}
                    onClick={() =>
                      openCourseHandler(sub._id, sub.name, "CrashCourse")
                    }
                  >
                    Explore Crash Courses
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes_api.button}
                    onClick={() =>
                      openCourseHandler(sub._id, sub.name, "Course")
                    }
                  >
                    Explore Courses
                  </Button>
                </div>
              </Subject>
            ))}
          </div>
          {isSubject && <SubjectForm setIsSubject={setIsSubject} />}
          {isteacher && <TeacherForm setIsTeacher={setIsTeacher} />}
        </div>
      )}
    </div>
  );
};
export default Home;
