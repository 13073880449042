import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionToken } from "../../store/auth";
import { getCompetitionApi, getCompetitions } from "../../store/uiStore";
import Card from "../UI/Card";
import AddCompetitionForm from "../UI/Form/AddCompetitionForm";
import classes from "./Competition.module.scss";

export default function Competition() {
  const useStyles = makeStyles({
    button: {
      marginLeft: "1rem",
    },
  });
  const classes_api = useStyles();
  const [addCompetition, setaddCompetition] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector(sessionToken);

  const competitions = useSelector(getCompetitions);
  useEffect(() => {
    competitions === "" && dispatch(getCompetitionApi(token));
  }, [dispatch, token, competitions]);
  console.log(competitions);
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes_api.button}
        onClick={() => setaddCompetition(true)}
      >
        Create Competition
      </Button>
      <div className={classes.switch_tab_container}>
        <div className={classes.switch_tab}></div>
      </div>
      {addCompetition ? (
        <AddCompetitionForm setaddCompetition={setaddCompetition} />
      ) : null}
      <div className={classes.competition_container}>
        {competitions !== "" &&
          competitions.map((competition) => (
            <Card key={competition._id}>
              <div>
                <h1>{competition.name}</h1>
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
}
