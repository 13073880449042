import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth";
import formSlice from "./forms";
import uiSlice from "./uiStore";

export const Store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    form: formSlice.reducer,
  },
});

export const authActions = authSlice.actions;
export const uiActions = uiSlice.actions;
export const formActions = formSlice.actions;
