import Card from "../UI/Card";
import classes from "./login.module.scss";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useRef } from "react";
import { login, sessionToken } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSubjectsApi, getTeachersApi } from "../../store/uiStore";

const useStyles = makeStyles({
  root: {
    border: 2,
    margin: "auto",
    boxSizing: "border-box",
    borderColor: "yellow",
    width: "100%",
    marginBottom: "2rem",
  },
  button: {
    display: "block",
    margin: "auto",
  },
});

const Login = (props) => {
  const dispatch = useDispatch();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const token = useSelector(sessionToken);
  const history = useHistory();
  useEffect(() => {
    if (token !== "") {
      dispatch(getSubjectsApi(token));
      dispatch(getTeachersApi(token));
      history.replace("/home");
    }
  }, [token, history, dispatch]);

  const onClickHandler = (event) => {
    event.preventDefault();
    dispatch(login(usernameRef.current.value, passwordRef.current.value));
  };

  const classes_api = useStyles();
  return (
    <div>
      <h2 className={classes.title}>Admin Login</h2>
      <Card className={classes.heading}>
        <TextField
          color="secondary"
          className={classes_api.root}
          id="standard-basic"
          label="Username"
          inputRef={usernameRef}
        />
        <TextField
          color="secondary"
          className={classes_api.root}
          id="standard-basic"
          label="Password"
          type="password"
          autoComplete="current-password"
          inputRef={passwordRef}
        />
        <Button
          variant="contained"
          color="secondary"
          className={classes_api.button}
          onClick={onClickHandler}
        >
          Login
        </Button>
      </Card>
    </div>
  );
};

export default Login;
