import { Button, TextField } from "@material-ui/core";
import Card from "../Card";
import Modal from "../Modal";
import classes from "./addcompetitionform.module.scss";
import { makeStyles } from "@material-ui/styles";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCompetitionApi, createQuizApi } from "../../../store/forms";
import { sessionToken } from "../../../store/auth";
import DateTimePicker from "react-datetime-picker";

const AddCompetitionForm = (props) => {
  const useStyles = makeStyles({
    button: {
      marginLeft: "1rem",
      height: "3rem",
      margin: "auto",
    },
  });
  const name = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [voting_end_date, setVotingEndDate] = useState(new Date());
  const [entries_close_date, setEntriesCloseDate] = useState(new Date());
  const [comp_end_date, setCompEndDate] = useState(new Date());
  const [top_ten_end, setTopTenEnd] = useState(new Date());

  const [description, setDescriptionList] = useState([""]);
  const [image, setimage] = useState("");
  const [file, setFile] = useState("");

  const dispatch = useDispatch();

  const token = useSelector(sessionToken);
  const addNewFormHandler = (event) => {
    event.preventDefault();
    dispatch(
      createCompetitionApi(
        token,
        name.current.value,
        description,
        startDate,
        entries_close_date,
        top_ten_end,
        voting_end_date,
        comp_end_date,
        file
      )
    );

    props.setaddCompetition(false);
    name.current.value = "";
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...description];
    list.splice(index, 1);
    setDescriptionList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDescriptionList([...description, ""]);
    console.log(description);
  };

  const handleChangeController = (event) => {
    setFile(event.target.files[0]);
    setimage(URL.createObjectURL(event.target.files[0]));
  };

  const handleDescriptionInputChange = (e, index) => {
    console.log(e.target.value);

    const list = [...description];
    list[index] = e.target.value;
    setDescriptionList(list);
  };

  const classes_api = useStyles();
  return (
    <Modal>
      <Card className={classes.popup}>
        <div
          onClick={() => props.setaddCompetition(false)}
          className={classes.close_button}
        >
          Close
        </div>
        <div className={classes.image_container}>
          <img src={image} alt="Course" className={classes.image} />
          <input
            type="file"
            onChange={handleChangeController}
            className={classes.image_input}
            id="file"
          />
          <label htmlFor="file">Select Image</label>
        </div>
        <TextField label="Competition Name" inputRef={name} />
        <div className={classes.container}>
          <div className={classes.sub_container}>
            <h5>Competition Start Date</h5>
            <DateTimePicker
              name="startDate"
              onChange={setStartDate}
              value={startDate}
              disableClock={true}
              minDate={new Date()}
            />
            <h5>Entries Close Date</h5>
            <DateTimePicker
              name="entries_close_date"
              onChange={setEntriesCloseDate}
              value={entries_close_date}
              disableClock={true}
              minDate={startDate}
            />
            <h5>Top Ten Selection Finalization Date</h5>
            <DateTimePicker
              name="top_ten_end_date"
              onChange={setTopTenEnd}
              value={top_ten_end}
              disableClock={true}
              minDate={entries_close_date}
            />
          </div>
          <div className={classes.sub_container}>
            <h5>Voting Lines Closing Date</h5>
            <DateTimePicker
              name="voting_close_date"
              onChange={setVotingEndDate}
              value={voting_end_date}
              disableClock={true}
              minDate={top_ten_end}
            />
            <h5>Competition End Date</h5>
            <DateTimePicker
              name="comp_end_date"
              onChange={setCompEndDate}
              value={comp_end_date}
              disableClock={true}
              minDate={voting_end_date}
            />
          </div>
        </div>
        {description.map((x, i) => {
          return (
            <div className="box">
              <TextField
                name="About the Competition"
                placeholder="About the Competition"
                value={x}
                onChange={(e) => handleDescriptionInputChange(e, i)}
              />

              <div className="btn-box">
                {description.length !== 1 && (
                  <button onClick={() => handleRemoveClick(i)}>Remove</button>
                )}
                {description.length - 1 === i && (
                  <button onClick={handleAddClick}>Add</button>
                )}
              </div>
            </div>
          );
        })}
        <Button
          variant="contained"
          className={classes_api.button}
          color="secondary"
          onClick={addNewFormHandler}
        >
          Add New Competition
        </Button>
      </Card>
    </Modal>
  );
};

export default AddCompetitionForm;
