import { useSelector } from "react-redux";
import { S3_CDN } from "../../constants";
import { getTeachers } from "../../store/uiStore";
import Card from "../UI/Card";
import classes from "./teachers.module.scss";

const Teachers = () => {
  const teachers = useSelector(getTeachers);
  return (
    <Card className={classes.container}>
      {teachers.map((teacher) => (
        <div className={classes.sub_container} key={teacher.key_name}>
          <img
            src={`${S3_CDN}/${teacher.key_name}`}
            alt={teacher.name}
            className={classes.image}
          />
          <span className={classes.data}>
            <h4 style={{ margin: 0 }}>Name:</h4> <div>{teacher.name}</div>
          </span>
          <span className={classes.data}>
            <h4 style={{ margin: 0 }}>Experience:</h4>{" "}
            <div>{teacher.experience}</div>
          </span>
          <span className={classes.data}>
            <h4 style={{ margin: 0 }}>Students Trained:</h4>{" "}
            <div>{teacher.trained}</div>
          </span>
          <span className={classes.data}>
            <h4 style={{ margin: 0 }}>Success:</h4> <div>{teacher.success}</div>
          </span>
          <span className={classes.data}>
            <h4 style={{ margin: 0 }}>Contact Number:</h4>{" "}
            <div>{teacher.number}</div>
          </span>
          <span className={classes.data}>
            <h4 style={{ margin: 0 }}>Degree:</h4> <div>{teacher.degree}</div>
          </span>
        </div>
      ))}
    </Card>
  );
};
export default Teachers;
