import { Button } from "@material-ui/core";
import Card from "../Card";
import Modal from "../Modal";
import classes from "./liveclassform.module.scss";
import { makeStyles } from "@material-ui/styles";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { createLiveClassApi } from "../../../store/forms";
import { sessionToken } from "../../../store/auth";
import DateTimePicker from "react-datetime-picker";
import { Autocomplete } from "@material-ui/lab";
import { getTeachers } from "../../../store/uiStore";

const useStyles = makeStyles({
  button: {
    height: "3rem",
    margin: "auto",
    padding: "0.5rem",
  },
});
const LiveClassForm = (props) => {
  const className = useRef();
  const feature1 = useRef();
  const feature2 = useRef();
  const feature3 = useRef();
  const subject = useRef();
  const [startDate, onChangeStart] = useState(new Date());
  const [endDate, onChangeEnd] = useState(new Date());
  const [value, setValue] = useState();
  const dispatch = useDispatch();
  const token = useSelector(sessionToken);
  const teachers = useSelector(getTeachers);
  const addNewFormHandler = (event) => {
    event.preventDefault();
    dispatch(
      createLiveClassApi(
        token,
        `${
          startDate.getMonth() + 1
        }-${startDate.getDate()}-${startDate.getFullYear()} ${startDate.getHours()}:${startDate.getMinutes()}`,
        `${
          endDate.getMonth() + 1
        }-${endDate.getDate()}-${endDate.getFullYear()} ${endDate.getHours()}:${endDate.getMinutes()}`,
        className.current.value,
        value._id,
        feature1.current.value,
        feature2.current.value,
        feature3.current.value,
        subject.current.value
      )
    );
    console.log(
      `${
        startDate.getMonth() + 1
      }-${startDate.getDate()}-${startDate.getFullYear()} ${startDate.getHours()}:${startDate.getMinutes()}`,
      `${
        endDate.getMonth() + 1
      }-${endDate.getDate()}-${endDate.getFullYear()} ${endDate.getHours()}:${endDate.getMinutes()}`
    );
    props.setaddLiveClass(false);
  };
  const classes_api = useStyles();
  return (
    <Modal>
      <Card className={classes.popup}>
        <div
          onClick={() => props.setaddLiveClass(false)}
          className={classes.close_button}
        >
          Close
        </div>
        <div className={classes.container}>
          <div className={classes.sub_container}>
            <h5>Start Date</h5>
            <DateTimePicker
              onChange={onChangeStart}
              value={startDate}
              disableClock={true}
              minDate={new Date()}
            />
          </div>
          <div className={classes.sub_container}>
            <h5>End Date</h5>
            <DateTimePicker
              onChange={onChangeEnd}
              value={endDate}
              disableClock={true}
              minDate={startDate}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.sub_container}>
            <h5>Enter Class Name</h5>
            <TextField maxRows="1" fullWidth inputRef={className} />
          </div>
          <div className={classes.sub_container}>
            <h5>Select Teacher</h5>
            <Autocomplete
              limitTags={-1}
              autoComplete
              id="multiple-limit-tags"
              options={teachers}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  variant="outlined"
                  label="Select Teachers"
                  placeholder="Select Teachers"
                />
              )}
            />
          </div>
        </div>

        <div className={classes.sub_container_features}>
          <TextField fullWidth label="Enter Feature 1" inputRef={feature1} />
          <TextField fullWidth label="Enter Feature 2" inputRef={feature2} />
          <TextField fullWidth label="Enter Feature 3" inputRef={feature3} />
        </div>

        <div className={classes.sub_container_features}>
          <TextField label="Enter Subject" inputRef={subject} />
        </div>

        <Button
          variant="contained"
          className={classes_api.button}
          color="secondary"
          onClick={addNewFormHandler}
        >
          Add New Live Class
        </Button>
      </Card>
    </Modal>
  );
};

export default LiveClassForm;
