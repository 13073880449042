import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import uiSlice, {
  getStoriesApi,
  getupcomingLiveApi,
  getUpcomingQuizApi,
} from "./uiStore";
import { BASE_URL } from "../constants";
import authSlice from "./auth";
import { formData } from "./functions";
import {
  getCoursesApi,
  getCrashCoursesApi,
  getSubjectsApi,
  getTeachersApi,
  getCompetitionApi,
} from "./uiStore";

const initialState = {
  video_url: "",
  notes_url: "",
};
const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setVideoUrl(state, action) {
      state.video_url = action.payload;
    },
    setNotesUrl(state, action) {
      state.notes_url = action.payload;
    },
  },
});

export const uploadVideoApi = (token, selectedFile) => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  return async (dispatch) => {
    const uploadVideoCall = async () => {
      const response = await Axios({
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        data: formData,
        url: "/upload", // route name
        baseURL: BASE_URL, //local url
        onUploadProgress: async (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
          dispatch(
            uiSlice.actions.setUploadPercentage(uploadPercentage.toFixed(2))
          );
        },
      });

      const response_data = response.data;

      return response_data;
    };
    try {
      const uploadData = await uploadVideoCall();
      dispatch(uiSlice.actions.setUploadPercentage(0));
      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(formSlice.actions.setVideoUrl(uploadData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const uploadNotesApi = (token, selectedFile) => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  return async (dispatch) => {
    const uploadVideoCall = async () => {
      const response = await Axios({
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        data: formData,
        url: "/upload/notes", // route name
        baseURL: BASE_URL, //local url
        onUploadProgress: async (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
          dispatch(
            uiSlice.actions.setUploadPercentage(uploadPercentage.toFixed(2))
          );
        },
      });

      const response_data = response.data;

      return response_data;
    };
    try {
      const uploadData = await uploadVideoCall();
      dispatch(uiSlice.actions.setUploadPercentage(0));
      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(formSlice.actions.setNotesUrl(uploadData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createClassApi = (
  token,
  course_id,
  class_name,
  description,
  video_url,
  notes_url,
  picture,
  subject_id,
  location
) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/new/class");
  var formData = new FormData();

  if (location === "/courses") {
    formData.append("course_id", course_id);
  } else if (location === "/crash/courses") {
    formData.append("crash_course_id", course_id);
  }

  formData.append("name", class_name);
  for (var i = 0; i < description.length; i++) {
    formData.append(`description[${i}]`, description[i]);
  }
  formData.append("video_url", video_url);
  formData.append("notes_url", notes_url);
  formData.append("picture", picture);
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createClassCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }
      const data = response.clone().json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createClassCall();

      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        if (location === "/courses") {
          await dispatch(getCoursesApi(token, subject_id));
        } else if (location === "/crash/courses") {
          await dispatch(getCrashCoursesApi(token, subject_id));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createAssignmentApi = (
  token,
  class_id,
  question,
  option1,
  option2,
  option3,
  option4,
  answer,
  subject_id,
  location
) => {
  var baseUrl;
  var params;
  const endpoint = BASE_URL;
  if (location === "/quiz") {
    baseUrl = new URL(endpoint + "/create/question");
  } else {
    baseUrl = new URL(endpoint + "/create/new/assignment");
  }
  if (location === "/quiz") {
    params = {
      quiz_id: class_id,
      question,
      option1,
      option2,
      option3,
      option4,
      answer,
    };
  } else {
    params = {
      class_id,
      question,
      option1,
      option2,
      option3,
      option4,
      answer,
    };
  }

  const body = formData(params);
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createAssignmentCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: body,
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createAssignmentCall();

      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        if (location === "/courses") {
          await dispatch(getCoursesApi(token, subject_id));
        } else if (location === "/crash/courses") {
          await dispatch(getCrashCoursesApi(token, subject_id));
        } else if (location === "/quiz") {
          await dispatch(getUpcomingQuizApi(token));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createSubjectApi = (token, name) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/subject");
  var params = {
    name,
  };
  const body = formData(params);
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createSubjectCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: body,
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createSubjectCall();
      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getSubjectsApi(token));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createCourseApi = (
  token,
  subject_id,
  name,
  picture,
  teachers,
  price,
  discounted_price,
  overview,
  learn,
  requirement
) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/course");
  const formData = new FormData();
  formData.append("name", name);
  formData.append("subject_id", subject_id);
  formData.append("picture", picture);
  formData.append("price", price);
  formData.append("discount_price", discounted_price);
  formData.append("overview", overview);
  for (var i = 0; i < teachers.length; i++) {
    formData.append(`teacher_id[${i}]`, teachers[i]);
  }
  for (i = 0; i < learn.length; i++) {
    formData.append(`learn[${i}]`, learn[i]);
  }
  for (i = 0; i < requirement.length; i++) {
    formData.append(`target[${i}]`, requirement[i]);
  }
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createCourseCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createCourseCall();
      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getCoursesApi(token, subject_id));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createTeacherApi = (
  token,
  name,
  number,
  degree,
  experience,
  students_trained,
  success,
  picture
) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/teacher");
  const formData = new FormData();
  formData.append("name", name);
  formData.append("number", number);
  formData.append("degree", degree);
  formData.append("experience", experience);
  formData.append("trained", students_trained);
  formData.append("success", success);
  formData.append("picture", picture);

  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createTeacherCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }
      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };
    try {
      const teacherData = await createTeacherCall();

      if (teacherData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getTeachersApi(token));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createCrashCourseApi = (
  token,
  subject_id,
  name,
  picture,
  teachers,
  price,
  discounted_price,
  overview,
  learn,
  requirement
) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/crash/course");
  const formData = new FormData();
  formData.append("name", name);
  formData.append("subject_id", subject_id);
  formData.append("picture", picture);
  formData.append("price", price);
  formData.append("discount_price", discounted_price);
  formData.append("overview", overview);
  for (var i = 0; i < teachers.length; i++) {
    formData.append(`teacher_id[${i}]`, teachers[i]);
  }
  for (i = 0; i < learn.length; i++) {
    formData.append(`learn[${i}]`, learn[i]);
  }
  for (i = 0; i < requirement.length; i++) {
    formData.append(`target[${i}]`, requirement[i]);
  }
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createCrashCourseCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createCrashCourseCall();
      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getCrashCoursesApi(token, subject_id));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createStoryApi = (token, title, picture, thumbnail) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/story");
  const formData = new FormData();
  formData.append("title", title);
  formData.append("picture", picture);
  formData.append("picture", thumbnail);
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createStoryCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createStoryCall();

      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getStoriesApi(token));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createQuizApi = (token, start, end) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/quiz");
  const formData = new FormData();
  formData.append("start", start);
  formData.append("end", end);
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createQuizCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createQuizCall();

      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getUpcomingQuizApi(token));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createLiveClassApi = (
  token,
  start,
  end,
  classname,
  teacher,
  feature1,
  feature2,
  feature3,
  subject
) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/live/class");
  const formData = new FormData();
  formData.append("start", start);
  formData.append("end", end);
  formData.append("teacher", teacher);
  formData.append("name", classname);
  formData.append("feature", feature1);
  formData.append("feature", feature2);
  formData.append("feature", feature3);
  formData.append("subject", subject);
  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createLiveClassCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }

      const data = response.json();
      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };

    try {
      const uploadData = await createLiveClassCall();

      if (uploadData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getupcomingLiveApi(token));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createCompetitionApi = (
  token,
  name,
  about,
  start,
  entry_end,
  top_ten_end,
  voting_lines_end,
  result_end,
  picture
) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/create/competition");
  const formData = new FormData();
  formData.append("name", name);
  formData.append("start", start);
  formData.append("entry_end", entry_end);
  formData.append("voting_lines_end", voting_lines_end);
  formData.append("top_ten_end", top_ten_end);
  formData.append("result_end", result_end);
  formData.append("picture", picture);
  for (var i = 0; i < about.length; i++) {
    formData.append(`about[${i}]`, about[i]);
  }

  return async (dispatch) => {
    dispatch(uiSlice.actions.setLoader(true));
    const createCompetitionCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      if (!response.ok) {
        dispatch(uiSlice.actions.setLoader(false));
        throw new Error("something went wrong");
      }
      const data = response.json();

      dispatch(uiSlice.actions.setLoader(false));
      return data;
    };
    try {
      console.log(formData);
      const teacherData = await createCompetitionCall();
      console.log(teacherData);
      if (teacherData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(getCompetitionApi(token));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getVideoUrl = (state) => state.form.video_url;
export const getNotesUrl = (state) => state.form.notes_url;
export default formSlice;
