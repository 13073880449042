import Modal from "../Modal";
import Card from "../Card";
import classes from "./teacherform.module.scss";
import { Button, TextField } from "@material-ui/core";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTeacherApi } from "../../../store/forms";
import { sessionToken } from "../../../store/auth";

const TeacherForm = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(sessionToken);
  const [image, setimage] = useState("");
  const [file, setFile] = useState("");
  const handleChangeController = (event) => {
    if (event.target.files[0].size > 100000) {
      alert("File size is too large, Compress Image under 50KB");
      return;
    } else {
      setFile(event.target.files[0]);
      setimage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const addTeacherHandler = () => {
    dispatch(
      createTeacherApi(
        token,
        name.current.value,
        number.current.value,
        degree.current.value,
        experience.current.value,
        students_trained.current.value,
        success.current.value,
        file
      )
    );
    props.setIsTeacher(false);
  };
  const name = useRef();
  const number = useRef();
  const students_trained = useRef();
  const degree = useRef();
  const experience = useRef();
  const success = useRef();
  return (
    <Modal>
      <Card className={classes.container}>
        <div
          onClick={() => props.setIsTeacher(false)}
          className={classes.close_button}
        >
          Close
        </div>

        <div className={classes.image_container}>
          <img src={image} alt="Teacher" className={classes.image} />
          <input
            type="file"
            onChange={handleChangeController}
            className={classes.image_input}
            id="file"
          />
          <label htmlFor="file">Select Image</label>
        </div>
        <div className={classes.name_number}>
          <TextField label="Enter Teacher's Name" inputRef={name} />
          <TextField label="Enter Phone Number" inputRef={number} />
        </div>

        <TextField
          label="Degree"
          variant="outlined"
          margin="normal"
          fullWidth
          inputRef={degree}
        />
        <TextField
          label="Student Success"
          variant="outlined"
          margin="normal"
          fullWidth
          inputRef={success}
        />
        <TextField
          label="Experience"
          variant="outlined"
          margin="normal"
          fullWidth
          inputRef={experience}
        />
        <TextField
          label="Students Trained"
          variant="outlined"
          margin="normal"
          fullWidth
          inputRef={students_trained}
        />
        <Button variant="contained" color="primary" onClick={addTeacherHandler}>
          Add Teacher
        </Button>
      </Card>
    </Modal>
  );
};

export default TeacherForm;
