import {
  TextField,
  Button,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./assignmentform.module.scss";
import Card from "../Card";
import Modal from "../Modal";
import { sessionToken } from "../../../store/auth";
import { getSubjectId } from "../../../store/uiStore";
import { createAssignmentApi } from "../../../store/forms";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles({
  radio: {
    marginTop: "2rem",
  },
});

const AssignmentForm = (props) => {
  const location = useLocation();
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const token = useSelector(sessionToken);
  const subject_id = useSelector(getSubjectId);
  const dispatch = useDispatch();
  const classes_api = useStyles();
  const question = useRef();
  const option1 = useRef();
  const option2 = useRef();
  const option3 = useRef();
  const option4 = useRef();
  var answer = useRef();
  const submitHandler = () => {
    if (value === "1") {
      answer = option1;
    } else if (value === "2") {
      answer = option2;
    } else if (value === "3") {
      answer = option3;
    } else if (value === "4") {
      answer = option4;
    }
    dispatch(
      createAssignmentApi(
        token,
        props.class_id,
        question.current.value,
        option1.current.value,
        option2.current.value,
        option3.current.value,
        option4.current.value,
        answer.current.value,
        subject_id,
        location.pathname
      )
    );
    console.log(props.class_id, subject_id, location.pathname);
    //props.setAddNewAssignment(false);
  };
  return (
    <Modal>
      <Card className={classes.container}>
        <div
          onClick={() => props.setAddNewAssignment(false)}
          className={classes.close_button}
        >
          X
        </div>
        <div className={classes.form}>
          <TextField
            label="Enter the Question"
            inputRef={question}
            multiline
          ></TextField>
          <TextField
            label="Enter Option No.1"
            inputRef={option1}
            multiline
          ></TextField>
          <TextField
            label="Enter Option No.2"
            inputRef={option2}
            multiline
          ></TextField>
          <TextField
            label="Enter Option No.3"
            inputRef={option3}
            multiline
          ></TextField>
          <TextField
            label="Enter Option No.4"
            inputRef={option4}
            multiline
          ></TextField>
          <FormControl component="fieldset" className={classes_api.radio}>
            <FormLabel component="legend">Choose Correct Answer</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Option 1"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Option 2"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Option 3"
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Option 4"
              />
            </RadioGroup>
          </FormControl>
          <Button onClick={submitHandler}>Submit</Button>
        </div>
      </Card>
    </Modal>
  );
};

export default AssignmentForm;
