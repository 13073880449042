import { createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../constants";

import { formData } from "./functions";

const initialAuthState = {
  username: "",
  password: "",
  isLoggedIn: false,
  session_token: "",
};

const authSlice = createSlice({
  name: "slice",
  initialState: initialAuthState,
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload.loggedBool;
      state.session_token = action.payload.token;
    },
  },
});

export const login = (email, password) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/login");

  var params = {
    email,
    password,
  };
  const body = formData(params);
  return async (dispatch) => {
    const loginApiCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      });
      console.log(response);
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const cityData = await loginApiCall();
      console.log(cityData);
      if (cityData.message === "Wrong Password") {
        await dispatch(
          authSlice.actions.setLoggedIn({ loggedBool: false, token: "" })
        );
      } else {
        await dispatch(
          authSlice.actions.setLoggedIn({
            loggedBool: true,
            token: cityData.sessionToken,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export default authSlice;
export const sessionToken = (state) => state.auth.session_token;
