import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { sessionToken } from "../../store/auth";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import StoryForm from "../UI/Form/StoryForm";
import {
  deleteStoryApi,
  getLoading,
  getStories,
  getStoriesApi,
} from "../../store/uiStore";
import Modal from "../UI/Modal";
import Card from "../UI/Card";
import classes from "./stories.module.scss";
import { S3_CDN } from "../../constants";
const useStyles = makeStyles({
  button: {
    margin: "1rem",
  },
});
const Stories = () => {
  const isLoading = useSelector(getLoading);
  const token = useSelector(sessionToken);
  const stories = useSelector(getStories);
  const dispatch = useDispatch();
  const [isStory, setIsStory] = useState(false);
  useEffect(() => {
    stories === "" && dispatch(getStoriesApi(token));
  }, [token, dispatch, stories]);
  console.log(stories);
  const subjectAddHandler = () => {
    setIsStory(true);
  };
  const deleteStoryHandler = (_id) => {
    dispatch(deleteStoryApi(token, _id));
  };
  const classes_api = useStyles();
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes_api.button}
        onClick={subjectAddHandler}
      >
        Add New Story
      </Button>
      <div className={classes.container}>
        {stories !== "" &&
          stories.map((story) => (
            <Card key={story._id} className={classes.sub_container}>
              <div className={classes.title}>{story.title}</div>
              <img
                src={`${S3_CDN}/${story.key_name}`}
                alt={story.title}
                className={classes.image}
              />
              <img
                src={`${S3_CDN}/${story.key_name_thumbnail}`}
                alt={story.title}
                className={classes.image_thumbnail}
              />
              <h5
                className={classes.delete}
                onClick={() => deleteStoryHandler(story._id)}
              >
                Delete
              </h5>
            </Card>
          ))}
      </div>
      {isStory && <StoryForm setIsStory={setIsStory} />}
      {isLoading && (
        <Modal>
          <Card className={classes.upload}>
            <h2>Loading</h2>
          </Card>
        </Modal>
      )}
    </div>
  );
};
export default Stories;
