import Modal from "../Modal";
import Card from "../Card";
import classes from "./subjectform.module.scss";
import { TextField, Button } from "@material-ui/core";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionToken } from "../../../store/auth";
import { createSubjectApi } from "../../../store/forms";

const SubjectForm = (props) => {
  const token = useSelector(sessionToken);
  const subjectName = useRef();
  const dispatch = useDispatch();
  const addSubjectHandler = () => {
    dispatch(createSubjectApi(token, subjectName.current.value));
    props.setIsSubject(false);
  };
  return (
    <Modal>
      <Card className={classes.container}>
        <div
          onClick={() => props.setIsSubject(false)}
          className={classes.close_button}
        >
          X
        </div>
        <div className={classes.sub_container}>
          <TextField
            label="Enter Subject Name"
            inputRef={subjectName}
          ></TextField>
          <Button color="primary" onClick={addSubjectHandler}>
            Add Subject
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default SubjectForm;
