import classes from "./newcourseform.module.scss";
import Modal from "../Modal";
import Card from "../Card";
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectId, getTeachers } from "../../../store/uiStore";
import { createCourseApi, createCrashCourseApi } from "../../../store/forms";
import { sessionToken } from "../../../store/auth";
import { Autocomplete } from "@material-ui/lab";
import { useLocation } from "react-router-dom";

const NewCourseForm = (props) => {
  const useStyles = makeStyles({
    select_teachers: {
      minWidth: "100%",
      width: "min-content",
    },
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const courseName = useRef();
  const coursePrice = useRef();
  const discountedPrice = useRef();
  const course_overview = useRef();
  const [learn, setLearnList] = useState([""]);
  const [requirements, setrequirementsList] = useState([""]);
  const subjectId = useSelector(getSubjectId);
  const token = useSelector(sessionToken);
  const [image, setimage] = useState();
  const [file, setFile] = useState();
  const [value, setValue] = useState();
  const teachers = useSelector(getTeachers);

  const handleLearnInputChange = (e, index) => {
    console.log(e.target.value);

    const list = [...learn];
    list[index] = e.target.value;
    setLearnList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...learn];
    list.splice(index, 1);
    setLearnList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setLearnList([...learn, ""]);
    console.log(learn);
  };

  const handleReqInputChange = (e, index) => {
    console.log(e.target.value);

    const list = [...requirements];
    list[index] = e.target.value;
    setrequirementsList(list);
  };
  // handle click event of the Remove button
  const handleReqRemoveClick = (index) => {
    const list = [...requirements];
    list.splice(index, 1);
    setrequirementsList(list);
  };

  // handle click event of the Add button
  const handleReqAddClick = () => {
    setrequirementsList([...requirements, ""]);
  };

  const addCourseHandler = () => {
    var teachers_id = [];
    for (var i = 0; i < value.length; i++) {
      teachers_id.push(value[i]._id);
    }
    if (location.pathname === "/courses") {
      dispatch(
        createCourseApi(
          token,
          subjectId,
          courseName.current.value,
          file,
          teachers_id,
          coursePrice.current.value,
          discountedPrice.current.value,
          course_overview.current.value,
          learn,
          requirements
        )
      );
    } else if (location.pathname === "/crash/courses") {
      dispatch(
        createCrashCourseApi(
          token,
          subjectId,
          courseName.current.value,
          file,
          teachers_id,
          coursePrice.current.value,
          discountedPrice.current.value,
          course_overview.current.value,
          learn,
          requirements
        )
      );
    }
    props.setAddNewCourse(false);
  };

  const uploadCourseImage = (event) => {
    if (event.target.files[0].size > 50000) {
      alert("File size is too large, Compress Image under 50KB");
      return;
    } else {
      setFile(event.target.files[0]);
      setimage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const classes_api = useStyles();
  return (
    <Modal>
      <Card className={classes.container}>
        <div
          onClick={() => props.setAddNewCourse(false)}
          className={classes.close_button}
        >
          Close
        </div>
        <div className={classes.sub_container}>
          <div className={classes.image_container}>
            <img src={image} alt="Course" className={classes.image} />
            <input
              type="file"
              onChange={uploadCourseImage}
              className={classes.image_input}
              id="file"
            />
            <label htmlFor="file">Select Image</label>
          </div>
          <div className={classes.form_fields}>
            <TextField label="Enter Course Name" inputRef={courseName} />
            <Autocomplete
              multiple
              limitTags={-1}
              autoComplete
              id="multiple-limit-tags"
              options={teachers}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              className={classes_api.select_teachers_container}
              renderInput={(params) => (
                <TextField
                  className={classes_api.select_teachers}
                  {...params}
                  multiline
                  variant="outlined"
                  label="Select Teachers"
                  placeholder="Select Teachers"
                />
              )}
            />
            <TextField
              multiline
              placeholder="Course Overview"
              inputRef={course_overview}
            />
            {learn.map((x, i) => {
              return (
                <div className="box">
                  <TextField
                    name="What you will learn"
                    placeholder="What you will learn"
                    value={x}
                    onChange={(e) => handleLearnInputChange(e, i)}
                  />

                  <div className="btn-box">
                    {learn.length !== 1 && (
                      <button onClick={() => handleRemoveClick(i)}>
                        Remove
                      </button>
                    )}
                    {learn.length - 1 === i && (
                      <button onClick={handleAddClick}>Add</button>
                    )}
                  </div>
                </div>
              );
            })}

            {requirements.map((x, i) => {
              return (
                <div className="box">
                  <TextField
                    name="Requirements"
                    placeholder="Requirements"
                    value={x}
                    onChange={(e) => handleReqInputChange(e, i)}
                  />

                  <div className="btn-box">
                    {requirements.length !== 1 && (
                      <button onClick={() => handleReqRemoveClick(i)}>
                        Remove
                      </button>
                    )}
                    {requirements.length - 1 === i && (
                      <button onClick={handleReqAddClick}>Add</button>
                    )}
                  </div>
                </div>
              );
            })}

            <Input
              placeholder="Price"
              aria-label="Price"
              id="standard-adornment-amount"
              inputRef={coursePrice}
              type="number"
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
            />
            <Input
              placeholder="Discounted Price"
              id="standard-adornment-amount"
              inputRef={discountedPrice}
              type="number"
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
            />

            <Button onClick={addCourseHandler} variant="contained">
              Add Course
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default NewCourseForm;
