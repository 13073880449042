import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { sessionToken } from "../../store/auth";
import {
  getCompletedQuiz,
  getCompletedQuizApi,
  getUpcomingQuiz,
  getUpcomingQuizApi,
} from "../../store/uiStore";
import Card from "../UI/Card";
import classes from "./quiz.module.scss";
import AddQuizForm from "../UI/Form/AddQuizForm";
import AssignmentForm from "../UI/Form/AssignmentForm";
const Quiz = () => {
  const useStyles = makeStyles({
    button: {
      marginLeft: "1rem",
    },
  });
  const dispatch = useDispatch();
  const token = useSelector(sessionToken);
  const upcomingQuizData = useSelector(getUpcomingQuiz);
  const completedQuizData = useSelector(getCompletedQuiz);
  useEffect(() => {
    upcomingQuizData === "" && dispatch(getUpcomingQuizApi(token));
    completedQuizData === "" && dispatch(getCompletedQuizApi(token));
    setQuizes(upcomingQuizData);
  }, [token, dispatch, upcomingQuizData, completedQuizData]);
  const [upcomingActive, setupcomingActive] = useState(true);
  const [completedActive, setcompletedActive] = useState(false);
  var [quizes, setQuizes] = useState(upcomingQuizData);
  const [addQuiz, setaddQuiz] = useState(false);
  const [addNewQuestion, setaddNewQuestion] = useState(false);
  const [quizId, setquizId] = useState();
  const onAddAssignmentHandler = (id) => {
    setquizId(id);
    setaddNewQuestion(true);
  };
  const quizChangeHandler = (type) => {
    if (type === 1) {
      setupcomingActive(true);
      setcompletedActive(false);
      setQuizes(upcomingQuizData);
    } else if (type === 2) {
      setupcomingActive(false);
      setcompletedActive(true);
      setQuizes(completedQuizData);
    }
  };
  const classes_api = useStyles();
  console.log(upcomingQuizData);
  console.log(completedQuizData);
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes_api.button}
        onClick={() => setaddQuiz(true)}
      >
        Add New Quiz
      </Button>
      <div className={classes.switch_tab_container}>
        <div className={classes.switch_tab}>
          <span
            className={
              upcomingActive
                ? `${classes.active} ${classes.tab}`
                : `${classes.tab}`
            }
            onClick={() => quizChangeHandler(1)}
          >
            Upcoming Quiz
          </span>
          <span
            className={
              completedActive
                ? `${classes.active} ${classes.tab}`
                : `${classes.tab}`
            }
            onClick={() => quizChangeHandler(2)}
          >
            Completed Quiz
          </span>
        </div>
        <div>
          {quizes !== "" &&
            quizes.map((quiz) => (
              <Card key={quiz._id} className={classes.container}>
                <div>
                  <span>Start Date: </span>
                  <span>{new Date(quiz.startDate).toLocaleString()}</span>
                </div>

                <div>
                  <span>End Date: </span>
                  <span>{new Date(quiz.endDate).toLocaleString()}</span>
                </div>
                <div className={classes.questions_list}>
                  {quiz.questions.map((question) => (
                    <Card className={classes.questions} key={question.answer}>
                      <div>
                        <span>
                          <p>{question.question}</p>
                        </span>
                      </div>
                    </Card>
                  ))}
                </div>
                {quiz.questions.length <= 15 && upcomingActive && (
                  <div className={classes.new_question}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => onAddAssignmentHandler(quiz._id)}
                    >
                      Add New Question
                    </Button>
                  </div>
                )}
              </Card>
            ))}
        </div>
      </div>
      {addQuiz && <AddQuizForm setaddQuiz={setaddQuiz} />}
      {addNewQuestion && (
        <AssignmentForm
          setAddNewAssignment={setaddNewQuestion}
          class_id={quizId}
        />
      )}
    </div>
  );
};

export default Quiz;
