import { useRef, useState } from "react";
import Card from "../Card";
import Modal from "../Modal";
import classes from "./classform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createClassApi,
  getNotesUrl,
  getVideoUrl,
  uploadNotesApi,
  uploadVideoApi,
} from "../../../store/forms";
import { TextField, Button } from "@material-ui/core";
import { sessionToken } from "../../../store/auth";
import { getSubjectId } from "../../../store/uiStore";
import { formActions } from "../../../store";
import { useLocation } from "react-router-dom";
const ClassForm = (props) => {
  const nameRef = useRef();
  const [description, setDescriptionList] = useState([""]);
  const video_url = useSelector(getVideoUrl);
  const notes_url = useSelector(getNotesUrl);
  const location = useLocation();
  const token = useSelector(sessionToken);
  const subjectId = useSelector(getSubjectId);
  const [image, setimage] = useState("");
  const [file, setFile] = useState("");
  const dispatch = useDispatch();
  const videouploadHandler = (event) => {
    if (event.target.files[0]) {
      dispatch(uploadVideoApi(token, event.target.files[0]));
    }
  };
  const handleChangeController = (event) => {
    if (event.target.files[0].size > 50000) {
      alert("File size is too large, Compress Image under 50KB");
      return;
    } else {
      setFile(event.target.files[0]);
      setimage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const notesuploadHandler = (event) => {
    if (event.target.files[0]) {
      if (event.target.files[0].size > 500000) {
        alert("PDF Size size is too large, compress pdf under 500KB");
        return;
      } else {
        dispatch(uploadNotesApi(token, event.target.files[0]));
      }
    }
  };

  const handleDescriptionInputChange = (e, index) => {
    console.log(e.target.value);

    const list = [...description];
    list[index] = e.target.value;
    setDescriptionList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...description];
    list.splice(index, 1);
    setDescriptionList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDescriptionList([...description, ""]);
    console.log(description);
  };
  const submitFormHandler = () => {
    dispatch(
      createClassApi(
        token,
        props.course_id,
        nameRef.current.value,
        description,
        video_url,
        notes_url === "" ? " " : notes_url,
        file,
        subjectId,
        location.pathname
      )
    );
    dispatch(formActions.setVideoUrl(""));
    dispatch(formActions.setNotesUrl(""));
    nameRef.current.value = "";
    setDescriptionList([""]);
    props.setAddNew(false);
  };
  return (
    <Modal>
      <Card className={classes.popup}>
        <div
          onClick={() => props.setAddNew(false)}
          className={classes.close_button}
        >
          Close
        </div>
        <div className={classes.form}>
          <div className={classes.image_container}>
            <img src={image} alt="Course" className={classes.image} />
            <input
              type="file"
              onChange={handleChangeController}
              className={classes.image_input}
              id="file"
            />
            <label htmlFor="file">Select Image</label>
          </div>
          <h3>Class Name</h3>
          <TextField inputRef={nameRef}></TextField>
          <h3>Class Description</h3>
          {/* <TextField inputRef={descriptionRef} multiline></TextField> */}
          {description.map((x, i) => {
            return (
              <div className="box">
                <TextField
                  name="Class Description"
                  placeholder="Class Description"
                  value={x}
                  onChange={(e) => handleDescriptionInputChange(e, i)}
                />

                <div className="btn-box">
                  {description.length !== 1 && (
                    <button onClick={() => handleRemoveClick(i)}>Remove</button>
                  )}
                  {description.length - 1 === i && (
                    <button onClick={handleAddClick}>Add</button>
                  )}
                </div>
              </div>
            );
          })}
          <h3>Class Video</h3>
          <div>
            <input
              type="file"
              name="video"
              onChangeCapture={videouploadHandler}
            />
            {video_url !== "" ? <div>Successful</div> : <></>}
          </div>
          <h3>Class Notes</h3>
          <input
            type="file"
            name="notes"
            onChangeCapture={notesuploadHandler}
          />
          {notes_url !== "" ? <div>Successful</div> : <></>}
        </div>
        <Button color="primary" onClick={submitFormHandler} variant="contained">
          Add Class
        </Button>
      </Card>
    </Modal>
  );
};

export default ClassForm;
