import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getCourses,
  getCrashCourses,
  getLoading,
  getSubjectName,
  getUploadPercentage,
} from "../../store/uiStore";
import Card from "../UI/Card";
import classes_mod from "./course.module.scss";
import { Button, makeStyles } from "@material-ui/core";
import Modal from "../UI/Modal";
import ClassForm from "../UI/Form/ClassForm";
import AssignmentForm from "../UI/Form/AssignmentForm";
import NewCourseForm from "../UI/Form/NewCourseForm";
import ReactHlsPlayer from "react-hls-player/dist";
const Course = () => {
  const location = useLocation();
  const mainCourses = useSelector(getCourses);
  const crashCourses = useSelector(getCrashCourses);
  const subjectName = useSelector(getSubjectName);
  const useStyles = makeStyles({
    button: {
      padding: "1rem",
      height: "1rem",
      margin: "auto 0",
    },
  });
  const [expand, setExpand] = useState(false);
  const [addnewCourse, setAddNewCourse] = useState(false);
  const [course_id, setId] = useState("");
  const [class_id, setClassId] = useState("");
  const [addnewAssigment, setAddNewAssignment] = useState(false);
  const [addnew, setAddNew] = useState(false);
  const isLoading = useSelector(getLoading);
  const uploadPercentage = useSelector(getUploadPercentage);
  const onExpandHandler = (id) => {
    setId(id);
    setExpand(!expand);
  };
  var courses;
  if (location.pathname === "/courses") {
    courses = mainCourses;
  } else if (location.pathname === "/crash/courses") {
    courses = crashCourses;
  }
  console.log(courses);
  const onAddButtonHandler = (id) => {
    setId(id);
    setAddNew(true);
  };

  const onAddAssignmentHandler = (id) => {
    setClassId(id);
    setAddNewAssignment(true);
  };
  const classes_api = useStyles();
  return (
    <div>
      <span className={classes_mod.sub_header}>
        <h1>{subjectName}</h1>
        <Button
          variant="contained"
          color="secondary"
          className={classes_api.button}
          onClick={() => setAddNewCourse(true)}
        >
          {location.pathname === "/courses"
            ? "Add New Course"
            : "Add New Crash Course"}
        </Button>
      </span>

      <div>
        {courses === "" ? (
          <></>
        ) : (
          courses.map((course, idx) => (
            <Card key={course._id} className={classes_mod.course}>
              <div className={classes_mod.course_name}>{course.name}</div>
              <div className={classes_mod.course_details}>
                <div className={classes_mod.price}>
                  <h3 style={{ margin: 0 }}>Price</h3>
                  <h4 style={{ margin: 0 }}>{`₹${course.price}`}</h4>
                </div>
                {typeof course.discount_price !== typeof undefined && (
                  <div>
                    <h3 style={{ margin: 0 }}>Discounted Price</h3>
                    <h4 style={{ margin: 0 }}>{`₹${course.discount_price}`}</h4>
                  </div>
                )}
              </div>
              <Button
                onClick={() => onExpandHandler(course._id)}
                color="secondary"
              >
                View All Clases
              </Button>
              <Button
                color="primary"
                onClick={() => onAddButtonHandler(course._id)}
              >
                Add Class
              </Button>
              {expand === true && course_id === course._id ? (
                course.classes.map((classes, index) => (
                  <Card className={classes_mod.class} key={classes._id}>
                    <span className={classes_mod.sub_container}>
                      <h3>{classes.name}</h3>
                      <Button
                        color="secondary"
                        onClick={() => onAddAssignmentHandler(classes._id)}
                      >
                        Add New Question
                      </Button>
                    </span>
                    <span className={classes_mod.span}>
                      <h5>Description</h5>
                      <p>{classes.description}</p>
                      <ReactHlsPlayer
                        controls={true}
                        src={`https://d9kj6xjcfol40.cloudfront.net/lectures/Default/lectures/${classes.video_url}`}
                        className={classes_mod.video_container}
                        hlsConfig={{
                          maxLoadingDelay: 4,
                          minAutoBitrate: 1200000,
                        }}
                      />
                    </span>
                    <div className={classes_mod.assignments_list}>
                      {classes.assignments.map((assignment, index) => (
                        <Card
                          className={classes_mod.assignment}
                          key={assignment.answer}
                        >
                          <div>
                            <span>
                              <p>{assignment.question}</p>
                            </span>

                            {/* <p className={classes_mod.correct_answer}>
                              {assignment.answer}
                            </p> */}
                            {/*
                            <div className={classes_mod.answer_container}>
                              <h4>Options: </h4>
                              <h5 className={classes_mod.answers}>
                                {assignment.option1}
                              </h5>
                              <h5 className={classes_mod.answers}>
                                {assignment.option2}
                              </h5>
                              <h5 className={classes_mod.answers}>
                                {assignment.option3}
                              </h5>
                              <h5 className={classes_mod.answers}>
                                {assignment.option4}
                              </h5>
                            </div> */}
                          </div>
                        </Card>
                      ))}
                    </div>
                  </Card>
                ))
              ) : (
                <></>
              )}
            </Card>
          ))
        )}
      </div>
      {addnew && <ClassForm course_id={course_id} setAddNew={setAddNew} />}
      {addnewAssigment && (
        <AssignmentForm
          class_id={class_id}
          setAddNewAssignment={setAddNewAssignment}
        />
      )}
      {addnewCourse && <NewCourseForm setAddNewCourse={setAddNewCourse} />}
      {isLoading && (
        <Modal>
          <Card className={classes_mod.upload}>
            <h2>Loading</h2>
          </Card>
        </Modal>
      )}
      {uploadPercentage !== 0 && (
        <Modal>
          <Card className={classes_mod.upload}>
            <h2>{uploadPercentage}% Uploaded</h2>
          </Card>
        </Modal>
      )}
    </div>
  );
};

export default Course;
