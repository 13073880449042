import classes from "./card.module.scss";
const Card = (props) => {
  return (
    <>
      <div
        className={`${props.className} ${classes.container}`}
        onClick={props.onClick}
      >
        {props.children}
      </div>
      {props.classes && <div>{props.classes}</div>}
    </>
  );
};

export default Card;
