import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { sessionToken } from "../../store/auth";
import {
  getcompletedLiveApi,
  getCompletedLiveClass,
  getLiveClassToken,
  getLiveMessageApi,
  getLiveMessageToken,
  getLiveTokenApi,
  getupcomingLiveApi,
  getUpcomingLiveClass,
} from "../../store/uiStore";
import Card from "../UI/Card";
import classes from "./liveclass.module.scss";
import LiveClassForm from "../UI/Form/LiveClassForm";
import Modal from "../UI/Modal";
import { uiActions } from "../../store";
const LiveClass = () => {
  const useStyles = makeStyles({
    button: {
      marginLeft: "1rem",
    },
  });
  const dispatch = useDispatch();
  const token = useSelector(sessionToken);
  const Livetoken = useSelector(getLiveClassToken);
  const LiveMessagetoken = useSelector(getLiveMessageToken);
  const upcomingLiveClass = useSelector(getUpcomingLiveClass);
  const completedLiveClass = useSelector(getCompletedLiveClass);
  const [isLive, setIsLive] = useState(false);
  useEffect(() => {
    upcomingLiveClass === "" && dispatch(getupcomingLiveApi(token));
    completedLiveClass === "" && dispatch(getcompletedLiveApi(token));
    setLiveClass(upcomingLiveClass);
    return () => {
      dispatch(uiActions.setLiveClassToken(""));
      dispatch(uiActions.setLiveClassToken(""));
    };
  }, [token, dispatch, upcomingLiveClass, completedLiveClass]);

  useEffect(() => {
    if (Livetoken !== "") {
      setIsLive(true);
    }
  }, [Livetoken]);
  const [upcomingActive, setupcomingActive] = useState(true);
  const [completedActive, setcompletedActive] = useState(false);
  var [liveClass, setLiveClass] = useState(upcomingLiveClass);
  const [addLiveClass, setaddLiveClass] = useState(false);
  const [className, setClassName] = useState("");
  const quizChangeHandler = (type) => {
    if (type === 1) {
      setupcomingActive(true);
      setcompletedActive(false);
      setLiveClass(upcomingLiveClass);
    } else if (type === 2) {
      setupcomingActive(false);
      setcompletedActive(true);
      setLiveClass(completedLiveClass);
    }
  };
  const onJoinHandler = (channel) => {
    setClassName(channel);
    dispatch(getLiveTokenApi(token, channel));
    dispatch(getLiveMessageApi(token, channel));
  };
  const classes_api = useStyles();
  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes_api.button}
        onClick={() => setaddLiveClass(true)}
      >
        Add New Live Class
      </Button>
      <div className={classes.switch_tab_container}>
        <div className={classes.switch_tab}>
          <span
            className={
              upcomingActive
                ? `${classes.active} ${classes.tab}`
                : `${classes.tab}`
            }
            onClick={() => quizChangeHandler(1)}
          >
            Upcoming Live Classes
          </span>
          <span
            className={
              completedActive
                ? `${classes.active} ${classes.tab}`
                : `${classes.tab}`
            }
            onClick={() => quizChangeHandler(2)}
          >
            Completed Live Classes
          </span>
        </div>
        <div>
          {liveClass !== "" &&
            liveClass.map((live) => (
              <Card key={live._id} className={classes.container}>
                <div>
                  <span>Start Date: </span>
                  <span>{new Date(live.start).toLocaleString()}</span>
                </div>

                <div>
                  <span>End Date: </span>
                  <span>{new Date(live.end).toLocaleString()}</span>
                </div>
                <div>
                  <span>Teacher: </span>
                  <span>{live.teacher.name}</span>
                </div>
                <div>
                  <span>Class Name: </span>
                  <span>{live.name}</span>
                </div>
                <Button onClick={() => onJoinHandler(live.name)}>
                  Join Now
                </Button>
              </Card>
            ))}
        </div>
      </div>
      {addLiveClass && <LiveClassForm setaddLiveClass={setaddLiveClass} />}
      {isLive && (
        <Modal>
          <Card className={classes.card_container}>
            <div
              onClick={() => {
                setIsLive(false);
              }}
              className={classes.close_button}
            >
              Close
            </div>

            <div
              className={classes.text}
              onClick={() => navigator.clipboard.writeText(className)}
            >
              Click To Copy Class Name
            </div>

            <div
              className={classes.text}
              onClick={() => navigator.clipboard.writeText(Livetoken)}
            >
              Click To Copy Secret Token
            </div>
            <div
              className={classes.text}
              onClick={() => navigator.clipboard.writeText(LiveMessagetoken)}
            >
              Click To Copy Secret Chat Token
            </div>
          </Card>
        </Modal>
      )}
    </div>
  );
};

export default LiveClass;
