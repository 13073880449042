import { createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../constants";
import authSlice from "./auth";

const initialState = {
  subjects: "",
  isLoading: false,
  selectedSub: "",
  selectedSubId: "",
  courses: "",
  teachers: "",
  uploadPercentage: 0,
  crashCourses: "",
  stories: "",
  completedQuiz: "",
  upcomingQuiz: "",
  upcomingLiveClass: "",
  completedLiveClass: "",
  liveClassToken: "",
  liveMessageToken: "",
  competitions: "",
};
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSubjects(state, action) {
      state.subjects = action.payload;
    },
    setLoader(state, action) {
      state.isLoading = action.payload;
    },
    setSubjectDetails(state, action) {
      state.selectedSub = action.payload.subjectName;
      state.selectedSubId = action.payload.subjectId;
    },
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setTeachers(state, action) {
      state.teachers = action.payload;
    },
    setUploadPercentage(state, action) {
      state.uploadPercentage = action.payload;
    },
    setCrashCourses(state, action) {
      state.crashCourses = action.payload;
    },
    setStories(state, action) {
      state.stories = action.payload;
    },
    setCompletedQuiz(state, action) {
      state.completedQuiz = action.payload;
    },
    setUpcomingQuiz(state, action) {
      state.upcomingQuiz = action.payload;
    },
    setUpcomingLiveClass(state, action) {
      state.upcomingLiveClass = action.payload;
    },
    setCompletedLiveClass(state, action) {
      state.completedLiveClass = action.payload;
    },
    setLiveClassToken(state, action) {
      state.liveClassToken = action.payload;
    },
    setLiveMessageToken(state, action) {
      state.liveMessageToken = action.payload;
    },
    setCompetition(state, action) {
      state.competitions = action.payload;
    },
  },
});

export const getSubjectsApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/subjects");

  return async (dispatch) => {
    const getSubjectsCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const subjectsData = await getSubjectsCall();
      if (subjectsData.code === 400) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setSubjects(subjectsData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCoursesApi = (token, subject_id) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/get/all/courses");
  const params = { subject_id };
  baseUrl.search = new URLSearchParams(params).toString();
  return async (dispatch) => {
    const getCoursesCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const coursesData = await getCoursesCall();

      if (coursesData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setCourses(coursesData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTeachersApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/teachers");
  return async (dispatch) => {
    const getTeachersCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const teachersData = await getTeachersCall();

      if (teachersData.code === 403) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setTeachers(teachersData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCrashCoursesApi = (token, subject_id) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/get/all/crash/courses");
  const params = { subject_id };
  baseUrl.search = new URLSearchParams(params).toString();
  return async (dispatch) => {
    const getCrashCoursesCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const coursesData = await getCrashCoursesCall();

      if (coursesData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setCrashCourses(coursesData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStoriesApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/stories");
  return async (dispatch) => {
    const getStoriesCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const storiesData = await getStoriesCall();

      if (storiesData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setStories(storiesData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteStoryApi = (token, story_id) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/delete/story");
  const formData = new FormData();
  formData.append("story_id", story_id);
  return async (dispatch) => {
    const deleteStoryCall = async () => {
      const response = await fetch(baseUrl, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const storiesData = await deleteStoryCall();

      if (storiesData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setStories(storiesData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUpcomingQuizApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/upcoming/quiz");
  return async (dispatch) => {
    const getUpcomingQuizCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const quizData = await getUpcomingQuizCall();

      if (quizData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setUpcomingQuiz(quizData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCompletedQuizApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/completed/quiz");
  return async (dispatch) => {
    const getCompletedQuizCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const quizData = await getCompletedQuizCall();
      console.log(quizData);
      if (quizData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setCompletedQuiz(quizData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getupcomingLiveApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/live/classes");
  return async (dispatch) => {
    const getupcomingLiveApiCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const liveClassData = await getupcomingLiveApiCall();
      if (liveClassData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(
          uiSlice.actions.setUpcomingLiveClass(liveClassData.data)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getcompletedLiveApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/completed/live/classes");
  return async (dispatch) => {
    const getcompletedLiveApiCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const liveClassData = await getcompletedLiveApiCall();
      if (liveClassData.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(
          uiSlice.actions.setCompletedLiveClass(liveClassData.data)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getLiveTokenApi = (token, channel) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/live/token");
  var params = { channel, username: "" };
  baseUrl.search = new URLSearchParams(params).toString();
  return async (dispatch) => {
    const getLiveTokenApiCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const liveClassToken = await getLiveTokenApiCall();
      if (liveClassToken.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(
          uiSlice.actions.setLiveClassToken(liveClassToken.data.token)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getLiveMessageApi = (token, account) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/live/messaging/token");
  var params = { account };
  baseUrl.search = new URLSearchParams(params).toString();
  return async (dispatch) => {
    const getLiveMessageApiCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const liveMessageToken = await getLiveMessageApiCall();
      if (liveMessageToken.code === 401) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(
          uiSlice.actions.setLiveMessageToken(liveMessageToken.data.token)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCompetitionApi = (token) => {
  const endpoint = BASE_URL;
  var baseUrl = new URL(endpoint + "/competitions");
  return async (dispatch) => {
    const getCompetitionCall = async () => {
      const response = await fetch(baseUrl, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = response.json();
      return data;
    };
    try {
      const CompetitionData = await getCompetitionCall();

      if (CompetitionData.code === 403) {
        await dispatch(
          authSlice.actions.setLoggedIn({ bool: false, session_token: "" })
        );
      } else {
        await dispatch(uiSlice.actions.setCompetition(CompetitionData.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export default uiSlice;
export const getSubjects = (state) => state.ui.subjects;
export const getLoading = (state) => state.ui.isLoading;
export const getSubjectName = (state) => state.ui.selectedSub;
export const getSubjectId = (state) => state.ui.selectedSubId;
export const getCourses = (state) => state.ui.courses;
export const getTeachers = (state) => state.ui.teachers;
export const getUploadPercentage = (state) => state.ui.uploadPercentage;
export const getCrashCourses = (state) => state.ui.crashCourses;
export const getStories = (state) => state.ui.stories;
export const getUpcomingQuiz = (state) => state.ui.upcomingQuiz;
export const getCompletedQuiz = (state) => state.ui.completedQuiz;
export const getUpcomingLiveClass = (state) => state.ui.upcomingLiveClass;
export const getCompletedLiveClass = (state) => state.ui.completedLiveClass;
export const getLiveClassToken = (state) => state.ui.liveClassToken;
export const getLiveMessageToken = (state) => state.ui.liveMessageToken;
export const getCompetitions = (state) => state.ui.competitions;
