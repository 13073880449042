import { Button } from "@material-ui/core";
import Card from "../Card";
import Modal from "../Modal";
import classes from "./addquizform.module.scss";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createQuizApi } from "../../../store/forms";
import { sessionToken } from "../../../store/auth";
import DateTimePicker from "react-datetime-picker";

const AddQuizForm = (props) => {
  const useStyles = makeStyles({
    button: {
      marginLeft: "1rem",
      height: "3rem",
      margin: "auto",
    },
  });
  const [startDate, onChangeStart] = useState(new Date());
  const [endDate, onChangeEnd] = useState(new Date());
  const dispatch = useDispatch();
  const token = useSelector(sessionToken);
  const addNewFormHandler = (event) => {
    event.preventDefault();
    dispatch(createQuizApi(token, startDate, endDate));
    props.setaddQuiz(false);
  };
  const classes_api = useStyles();
  return (
    <Modal>
      <Card className={classes.popup}>
        <div
          onClick={() => props.setaddQuiz(false)}
          className={classes.close_button}
        >
          Close
        </div>
        <div className={classes.container}>
          <div className={classes.sub_container}>
            <h5>Start Date</h5>
            <DateTimePicker
              onChange={onChangeStart}
              value={startDate}
              disableClock={true}
              minDate={new Date()}
            />
          </div>
          <div className={classes.sub_container}>
            <h5>End Date</h5>
            <DateTimePicker
              onChange={onChangeEnd}
              value={endDate}
              disableClock={true}
              minDate={startDate}
            />
          </div>
        </div>
        <Button
          variant="contained"
          className={classes_api.button}
          color="secondary"
          onClick={addNewFormHandler}
        >
          Add New Quiz
        </Button>
      </Card>
    </Modal>
  );
};

export default AddQuizForm;
